<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button to="../list">
                    <b-icon icon="list"/>
                    Список
                </b-button>
                <b-button to="../add">
                    <b-icon icon="plus"/>
                    Добавить
                </b-button>
                <b-button to="edit">
                    <b-icon icon="pencil"/>
                    Редактировать
                </b-button>
                <b-button @click="doDelete()">
                    <b-icon icon="trash"/>
                    Удалить
                </b-button>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="!item">
            <b-table
                :stacked="true"
                :items="rows"
                :fields="fields"
            >
            </b-table>
        </b-overlay>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: "Id",
                },
                {
                    key: "email",
                    label: "Email",
                },
                {
                    key: "created_at",
                    label: "Добавлен",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
                {
                    key: "updated_at",
                    label: "Изменен",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
            ],
            item: null,
        };
    },
    computed: {
        rows() {
            if (!this.item) {
                return [];
            }

            return [this.item];
        },
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        refreshData() {
            const id = parseInt(this.$route.params.id);

            this.axios.get(`/admin_api/admins/${id}`).then(async ({data}) => {
                this.item = data;
            });
        },
        onDeleted() {

        },
        doDelete() {
            const id = parseInt(this.$route.params.id);

            this.axios.delete(`/admin_api/admins/${id}`).then(async () => {
                this.$alertify.success("Данные удалены");
                this.$router.push("../list");
            });
        },
    },
};
</script>
